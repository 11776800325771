import { useEffect, useState } from "react";
import ComponentSvg from "~/components/component-svg";

interface AuthLoadingScreenProps {
  initialStatus?: string;
  operation?: "login" | "registration";
  onComplete?: () => void;
}

export default function AuthLoadingScreen({
  initialStatus = "Connecting...",
  operation = "login",
  onComplete,
}: AuthLoadingScreenProps) {
  const [progress, setProgress] = useState(0);
  const [statusText, setStatusText] = useState(initialStatus);

  // Define status messages based on operation type
  const statusMessages = {
    login: {
      start: "Connecting...",
      step1: "Authenticating your account...",
      step2: "Verifying credentials...",
      step3: "Almost there...",
      complete: "Success! Redirecting...",
      error: "Authentication error. Please try again.",
    },
    registration: {
      start: "Creating your account...",
      step1: "Setting up your profile...",
      step2: "Establishing your account...",
      step3: "Almost ready...",
      complete: "Success! Welcome to FlirtyBum!",
      error: "Registration error. Please try again.",
    },
  };

  const messages = statusMessages[operation];

  // Simulate loading progress
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 95) {
          clearInterval(timer);
          return 95;
        }

        // Update status message based on progress
        if (oldProgress === 15) setStatusText(messages.step1);
        if (oldProgress === 40) setStatusText(messages.step2);
        if (oldProgress === 70) setStatusText(messages.step3);

        return Math.min(oldProgress + 5, 95);
      });
    }, 300);

    return () => clearInterval(timer);
  }, [messages]);

  // Handle completion
  useEffect(() => {
    if (progress === 95) {
      // Simulate a delay before completion
      const completionTimer = setTimeout(() => {
        setProgress(100);
        setStatusText(messages.complete);

        if (onComplete) {
          // Give a small delay to show the 100% state
          setTimeout(onComplete, 500);
        }
      }, 1000);

      return () => clearTimeout(completionTimer);
    }
  }, [progress, messages.complete, onComplete]);

  return (
    <div className="fixed inset-0 bg-background bg-opacity-90 z-50 flex flex-col items-center justify-center p-4">
      <div className="bg-componentBackground rounded-lg shadow-lg p-8 max-w-md w-full flex flex-col items-center animate-fadein">
        {/* Logo with pulse animation */}
        <div className="mb-8 animate-pulsesubtle">
          <ComponentSvg className="text-text w-48 h-auto" />
        </div>

        {/* Progress bar */}
        <div className="w-full mb-6">
          <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              className="h-full bg-gradient-to-r from-red-600 to-red-400 transition-all duration-300 ease-out"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>

        {/* Loading spinner and text */}
        <div className="flex items-center mb-4">
          <div className="relative w-8 h-8 mr-3">
            <div className="absolute w-8 h-8 border-3 border-t-transparent border-red-600 rounded-full animate-spin"></div>
          </div>
          <h2 className="text-xl font-bold text-text">{statusText}</h2>
        </div>

        {/* Tips or brand messaging */}
        <div className="mt-6 p-4 bg-gray-100 bg-opacity-10 rounded-lg border border-gray-200 border-opacity-20">
          <p className="text-muted text-center text-sm">
            {operation === "registration"
              ? "Welcome to FlirtyBum! Get ready to meet amazing people near you."
              : "Welcome back to FlirtyBum! We're excited to connect you with your matches."}
          </p>
        </div>
      </div>
    </div>
  );
}
