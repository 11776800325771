import React, { createContext, useContext, useState, ReactNode } from "react";
import AuthLoadingScreen from "~/components/auth-loading-screen";

interface AuthLoadingContextType {
  showLoading: (
    operation?: "login" | "registration",
    callback?: () => void
  ) => void;
  hideLoading: () => void;
}

const AuthLoadingContext = createContext<AuthLoadingContextType | undefined>(
  undefined
);

export function useAuthLoading() {
  const context = useContext(AuthLoadingContext);
  if (context === undefined) {
    throw new Error(
      "useAuthLoading must be used within an AuthLoadingProvider"
    );
  }
  return context;
}

interface AuthLoadingProviderProps {
  children: ReactNode;
}

export function AuthLoadingProvider({ children }: AuthLoadingProviderProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState<
    "login" | "registration"
  >("login");
  const [onCompleteCallback, setOnCompleteCallback] = useState<
    (() => void) | undefined
  >(undefined);

  const showLoading = (
    operation: "login" | "registration" = "login",
    callback?: () => void
  ) => {
    setLoadingOperation(operation);
    setOnCompleteCallback(() => callback);
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
    setOnCompleteCallback(undefined);
  };

  const handleComplete = () => {
    if (onCompleteCallback) {
      onCompleteCallback();
    }
    hideLoading();
  };

  return (
    <AuthLoadingContext.Provider value={{ showLoading, hideLoading }}>
      {children}
      {isLoading && (
        <AuthLoadingScreen
          operation={loadingOperation}
          onComplete={handleComplete}
        />
      )}
    </AuthLoadingContext.Provider>
  );
}
